import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { Breadcrumb, Container, Row, Col } from "react-bootstrap"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    console.log(this.props.pageContext)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Container>
          <Row>
            <Col md={12}>
              <Breadcrumb>
              <Breadcrumb.Item><Link to="/blog/">Blog</Link></Breadcrumb.Item>
              <Breadcrumb.Item active>
                {post.frontmatter.title}
              </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h1
                style={{
                  marginTop: rhythm(1),
                  marginBottom: 0,
                }}
              >
                {post.frontmatter.title}
              </h1>
            </Col>
            <Col md={12}>
              <p
                style={{
                  ...scale(-1 / 5),
                  display: `block`,
                  marginBottom: rhythm(1),
                }}
              >
                {post.frontmatter.date}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Img fluid={post.frontmatter.image.childImageSharp.fluid} alt={post.frontmatter.title} className="mb-4" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <hr
                style={{
                  marginBottom: rhythm(1),
                }}
              />
            </Col>
            <Col md={6} sm={6} xs={6}>
              {previous && (
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to={"/blog/" + previous.fields.slug} rel="prev">
                      ← {previous.frontmatter.title}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              )}
            </Col>
            <Col md={6} sm={6} xs={6}>
              {next && (
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to={"/blog/" + next.fields.slug} rel="next">
                      {next.frontmatter.title} →
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
                )}
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
                  fluid(maxWidth: 900, quality: 100) {
                    ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
